import React from 'react'
 import {StaticImage as Img} from "gatsby-plugin-image"
 import MapArea  from "./style"
export default function ContactMap(){
return(
<MapArea>
    <img src="https://res.cloudinary.com/dv10simqm/image/upload/v1642446878/helios/Contacto%20inversores/mapa_contacto_inversores_2x_boarwu.png" alt="map-image" className='w-100'/>
    {/* <MapArea.Card className="card-1">
        <p>4 G-ral. Traian Mosoiu Street, A Building, 2nd</p>
    </MapArea.Card>
    <MapArea.Card className="card-2">
        <p>Entrance, Flat 30, Cluj-Napoca, Romania</p>
    </MapArea.Card> */}
</MapArea>
)
}