import React from 'react'
import Form from './style';
 
export default function ContactForm({...rest}){
return(
<Form {...rest}>
  <h3 className="form-title">Déjanos tu mensaje y en breve una de nuestros ejecutivos se pondrá en contacto.</h3>
  <div className="contact-form">
    <form action="./">
      <div className="row">
        <div className="col-lg-4 mb-4">
          <div className="form-floating">
            <input className="form-control" placeholder="Correo electrónico" id="floatinginput" />
            <label htmlFor="floatinginput">Correo electrónico</label>
          </div>
        </div>
        <div className="col-lg-4 mb-4">
          <div className="form-floating">
            <input className="form-control" placeholder="Teléfono" id="floatinginput2" />
            <label htmlFor="floatinginput2">Teléfono</label>
          </div>
        </div>
        <div className="col-lg-4 mb-4">
          <div className="form-floating">
            <input className="form-control" placeholder="Sitio web" id="floatinginput3" />
            <label htmlFor="floatinginput3">Sitio web</label>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-floating">
            <textarea className="form-control" placeholder="Escribe tu mensaje aquí…" id="floatingTextarea" style={{height: 100}} defaultValue={""} />
            <label htmlFor="floatingTextarea">Escribe tu mensaje aquí…</label>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row align-items-center mt-3 justify-content-center">
          <button className="btn btn_investors btn-dark rounded-55 text-white">Enviar</button> 
            {/* <div className="col-md-8 col-lg-7 col-md-6 col-xl-8 pt-3">
              <div className="form-check d-flex align-items-center">
                <input className="form-check-input bg-white float-none mt-0" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Breath of that universal love which bears and sustains us, as it floats
                </label>
              </div>
            </div> */}
            {/* <div className=" text-md-end pt-3">
              
            </div> */}
          </div>
        </div>
      </div>
    </form>
  </div>
</Form>

)
}
